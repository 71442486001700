import { Screen } from "@/shared/ui/screen/Screen";

import { type ActivityLayoutComponentType } from "@/stackflow/routes/types";

const BaseActivityScreenLayout: ActivityLayoutComponentType = ({
  children,
}) => {
  return <Screen>{children}</Screen>;
};

export default BaseActivityScreenLayout;

import { Screen } from "@/shared/ui/screen/Screen";

import { type ActivityLayoutComponentType } from "@/stackflow/routes/types";

type PathParams = {
  /**
   * 로컬프로필 ID
   */
  local_profile_id: string;
};
type QueryParams = {};
export type ActivityLocalProfileUpdatePricingImageParams = PathParams &
  QueryParams;

const ActivityLocalProfileUpdatePricingImageLayout: ActivityLayoutComponentType<
  ActivityLocalProfileUpdatePricingImageParams
> = ({ children }) => {
  return (
    <Screen appBar={{ title: "가격표", border: false }}>{children}</Screen>
  );
};

export default ActivityLocalProfileUpdatePricingImageLayout;

import axios, { type AxiosInstance } from "axios";

import { BUSINESS_GATEWAY_SERVER_URL } from "@/shared/constants/serverURL";

let businessGatewayAxiosInstance: AxiosInstance | null;

const getBusinessGatewayAxiosInstance = () => {
  if (!businessGatewayAxiosInstance) {
    businessGatewayAxiosInstance = axios.create({
      baseURL: BUSINESS_GATEWAY_SERVER_URL,
      timeout: 5000,
    });
  }

  return businessGatewayAxiosInstance;
};

export const businessGatewayAxios = getBusinessGatewayAxiosInstance();

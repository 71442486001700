import { Screen } from "@/shared/ui/screen/Screen";

import { type ActivityLayoutComponentType } from "@/stackflow/routes/types";

type PathParams = {
  /**
   * 로컬프로필 ID
   */
  local_profile_id: string;
};
type QueryParams = {};
export type ActivityLocalProfileUpdatePhoneParams = PathParams & QueryParams;
const ActivityLocalProfileUpdatePhoneLayout: ActivityLayoutComponentType<
  ActivityLocalProfileUpdatePhoneParams
> = ({ children }) => {
  return (
    <Screen appBar={{ title: "전화번호", border: false }}>{children}</Screen>
  );
};

export default ActivityLocalProfileUpdatePhoneLayout;

import { ErrorBoundary } from "react-error-boundary";

import { Screen } from "@/shared/ui/screen/Screen";

import { MaintenanceModeSocialCapitalBoundary } from "@/widgets/social-capital/MaintenanceModeSocialCapitalBoundary";

import type { ActivityLayoutComponentType } from "@/stackflow/routes/types";
import { useNavigator } from "@/stackflow/useNavigator";

import { type ActivityParams } from "./ActivitySocialCapitalContributeReviewLanding";

const ActivitySocialCapitalContributeReviewLandingLayout: ActivityLayoutComponentType<
  ActivityParams
> = ({ children, params }) => {
  const { replace } = useNavigator();

  const handleOnReplaceActivity = () => {
    replace(
      "review.landing",
      { review_id: params.review_id },
      { animate: false },
    );
  };

  return (
    <Screen>
      <ErrorBoundary
        fallbackRender={() => {
          return null;
        }}
        onError={handleOnReplaceActivity}
      >
        <MaintenanceModeSocialCapitalBoundary
          fallback={null}
          onMaintenanceMode={handleOnReplaceActivity}
        >
          {children}
        </MaintenanceModeSocialCapitalBoundary>
      </ErrorBoundary>
    </Screen>
  );
};

export default ActivitySocialCapitalContributeReviewLandingLayout;

import { useEffect } from "react";

import { useRegisterScreenCommonPayloadEffect } from "@/shared/lib/local-business-analytics/localBusinessAnalytics";

import { type Activities } from "@/stackflow/Stack";
import { useStepNavigator } from "@/stackflow/useStepNavigator";

import { useUserProfileInfoByUserId } from "./useUserProfileInfoByUserId";

/**
 * 나의 당근에서 /profiles/me 로 접근하는 케이스를 대응하기 위한 컴포넌트
 * - URL 파라미터 변경을 위해 useEffect로 stepReplace를 실행합니다.
 * - analytics 로깅에 공통 파라미터를 담기 위해 registerScreenCommonPayloadEffect를 실행합니다.
 */
export const useRedirectUserProfileEffect = <
  K extends keyof Activities,
>(props: {
  activityName: K;
  profileUserId: string;
}) => {
  const { profileUserId, isMyProfile } = useUserProfileInfoByUserId({
    profileUserId: props.profileUserId,
  });
  const { stepReplace } = useStepNavigator(props.activityName);
  /**
   * URL의 /me를 현재 유저 ID로 변경합니다.
   * @example /profiles/me -> /profiles/123
   */
  useEffect(
    () => stepReplace({ profile_id: profileUserId } as any),
    [profileUserId, stepReplace],
  );
  /**
   * 유저 프로필 화면들에서 사용되는 로깅에 공통으로 아래 파라미터를 담습니다.
   * - profile_user_id: 프로필 유저 ID
   * - is_my_profile: 내 프로필 여부
   */
  useRegisterScreenCommonPayloadEffect(
    {
      profile_user_id: profileUserId,
      is_my_profile: isMyProfile,
    } satisfies { profile_user_id: number; is_my_profile: boolean },
    [],
  );
};

import { Screen } from "@/shared/ui/screen/Screen";

import { type ActivityLayoutComponentType } from "@/stackflow/routes/types";

const ActivityLocalProfileSuggestionCreateLandingLayout: ActivityLayoutComponentType =
  ({ children }) => {
    return <Screen>{children}</Screen>;
  };

export default ActivityLocalProfileSuggestionCreateLandingLayout;

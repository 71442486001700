import { type ReactNode } from "react";
import clsx from "clsx";
import {
  BoxButton,
  type SeedBoxButtonProps,
} from "@daangn/sprout-components-button";
import { AvoidSnackbarOverlap } from "@daangn/sprout-components-snackbar";

import { Flex } from "./Flex";
import * as css from "./BottomFixedButton.css";

export interface Props {
  children: ReactNode;
  actions?: [SeedBoxButtonProps] | [SeedBoxButtonProps, SeedBoxButtonProps];
  className?: string;
}
const BottomFixedButton = ({ children, className, actions }: Props) => {
  return (
    <Flex direction="column" className={clsx(css.base, className)}>
      <div className={css.body}>{children}</div>
      <AvoidSnackbarOverlap>
        <Flex className={css.footer} gap="0.5">
          {actions?.map((_action, index) => (
            <BoxButton
              key={`bottom_fixed_button_${index}`}
              width="100%"
              {..._action}
            />
          ))}
        </Flex>
      </AvoidSnackbarOverlap>
    </Flex>
  );
};

export { BottomFixedButton };

import { exhaustiveGuard } from "@/shared/utils/exaustiveGuard";
import { getSecureRandomFloat } from "@/shared/utils/number/getSecureRandomFloat";

import { SECOND } from "./constants";

type BackoffStrategy = "linear" | "exponential" | "exponential-jitter";
interface BackoffParams {
  strategy: BackoffStrategy;
  attemptCount: number;
  backoffLimit: number;
}
/**
 * Calculates the backoff time based on the provided strategy, attempt count, and backoff limit.
 *
 * @param {BackoffParams} params - The parameters for calculating the backoff time.
 * @param {BackoffStrategy} params.strategy - The backoff strategy to use. Can be "linear", "exponential", or "exponential-jitter".
 * @param {number} params.attemptCount - The number of attempts made so far.
 * @param {number} params.backoffLimit - The maximum backoff time allowed.
 * @returns {number} The calculated backoff time in milliseconds.
 */
export const calculateBackoff = ({
  strategy,
  attemptCount,
  backoffLimit,
}: BackoffParams): number => {
  const exponent = attemptCount <= 0 ? 0 : attemptCount - 1;
  let backoffTime: number;

  switch (strategy) {
    case "linear":
      backoffTime = attemptCount * SECOND;
      break;
    case "exponential":
      backoffTime = 0.3 * Math.pow(2, exponent) * SECOND;
      break;
    case "exponential-jitter":
      const exponentialBackoff = 0.3 * Math.pow(2, exponent) * SECOND;
      const jitter = getSecureRandomFloat() * exponentialBackoff;
      backoffTime = exponentialBackoff + jitter;
      break;
    default:
      return exhaustiveGuard(strategy);
  }

  return Math.min(backoffTime, backoffLimit);
};

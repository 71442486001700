import { type PropsWithChildren } from "react";
import clsx from "clsx";

import * as css from "./Flex.css";

export type FlexProps = css.BaseVariants & {
  onClick?: () => void;
  className?: string;
};
export const Flex = ({
  children,
  className,
  onClick,
  ...styleProps
}: PropsWithChildren<FlexProps>) => {
  return (
    <div
      className={clsx(
        css.base({
          align: styleProps.align,
          justify: styleProps.justify,
          direction: styleProps.direction,
          gap: styleProps.gap,
          width: styleProps.width,
          height: styleProps.height,
        }),
        className,
      )}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

import { useSuspenseQuery } from "@tanstack/react-query";

import { getRemoteConfig } from "@/shared/api/business-gateway/remote-config";

export const useSuspenseQueryRemoteConfig = () => {
  return useSuspenseQuery({
    queryKey: ["remote-config"],
    queryFn: async () => {
      const response = await getRemoteConfig({});

      return response.data;
    },
  });
};

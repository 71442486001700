import { Screen } from "@/shared/ui/screen/Screen";

import { type ActivityLayoutComponentType } from "@/stackflow/routes/types";

type PathParams = {
  /**
   * 로컬프로필 ID
   */
  local_profile_id: string;
};
type QueryParams = {};
export type ActivityLocalProfileUpdateAddressParams = PathParams & QueryParams;
const ActivityLocalProfileUpdateAddressLayout: ActivityLayoutComponentType<
  ActivityLocalProfileUpdateAddressParams
> = ({ children }) => {
  return <Screen appBar={{ title: "주소", border: false }}>{children}</Screen>;
};

export default ActivityLocalProfileUpdateAddressLayout;

import { Suspense } from "react";

import { Loading } from "@/shared/ui/loading/Loading";
import { Screen } from "@/shared/ui/screen/Screen";

import { type ActivityLayoutComponentType } from "@/stackflow/routes/types";

import { UpdateReviewErrorBoundary } from "./UpdateReviewErrorBoundary";

type PathParams = {
  /**
   * 후기 ID
   */
  review_id: string;
};
type QueryParams = {};

export type ActivityUpdateReviewParams = PathParams & QueryParams;

const ActivityUpdateReviewLayout: ActivityLayoutComponentType<
  ActivityUpdateReviewParams
> = ({ children }) => {
  return (
    <Screen appBar={{ title: "후기 수정" }}>
      <UpdateReviewErrorBoundary>
        <Suspense fallback={<Loading />}>{children}</Suspense>
      </UpdateReviewErrorBoundary>
    </Screen>
  );
};

export default ActivityUpdateReviewLayout;

import { type ReactNode, useLayoutEffect } from "react";

import { useSuspenseQueryRemoteConfig } from "@/shared/hooks/useSuspenseQueryRemoteConfig";

interface Props {
  fallback: ReactNode;
  children: ReactNode;
  onMaintenanceMode?: () => void;
}

const FallbackComponent = ({
  children,
  onMaintenanceMode,
}: Omit<Props, "fallback">) => {
  useLayoutEffect(() => {
    onMaintenanceMode?.();
  }, [onMaintenanceMode]);

  return <>{children}</>;
};

export const MaintenanceModeSocialCapitalBoundary = ({
  children,
  fallback,
  onMaintenanceMode,
}: Props) => {
  const { data } = useSuspenseQueryRemoteConfig();

  if (data.social_capital_maintenance_mode)
    return (
      <FallbackComponent onMaintenanceMode={onMaintenanceMode}>
        {fallback}
      </FallbackComponent>
    );

  return <>{children}</>;
};

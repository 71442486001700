import { Screen } from "@/shared/ui/screen/Screen";

import { type ActivityLayoutComponentType } from "@/stackflow/routes/types";

type PathParams = {
  /**
   * 로컬프로필 생성/수정/삭제 제안 폼 ID
   */
  local_profile_suggestion_id: string;
};
type QueryParams = {};

export type ActivityLocalProfileSuggestionLandingParams = PathParams &
  QueryParams;

const ActivityLocalProfileSuggestionLandingLayout: ActivityLayoutComponentType<
  ActivityLocalProfileSuggestionLandingParams
> = ({ children }) => {
  return <Screen>{children}</Screen>;
};

export default ActivityLocalProfileSuggestionLandingLayout;

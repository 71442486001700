import { type PropsWithChildren } from "react";
import { captureException, setUser as setSentryUser } from "@sentry/react";

import { useExternalPromiseLoader } from "@/shared/lib/karrot-bridge/external-promise-loader/useExternalPromiseLoader";

import { KARROT_BRIDGE_EXTERNAL_PROMISE_LOADER_KEY } from "@/features/karrot-bridge/constant/key";
import {
  type GetRegionInfo,
  type GetUserInfo,
} from "@/features/karrot-bridge/model/createExternalPromiseBridgeInfo";

export const Bridge = ({ children }: PropsWithChildren) => {
  useExternalPromiseLoader<GetUserInfo>({
    key: KARROT_BRIDGE_EXTERNAL_PROMISE_LOADER_KEY.USER_INFO,
    onSuccess: (user) => {
      setSentryUser({ id: user.id });
    },
    onFailure: (error) => {
      captureException(error);
    },
  });
  useExternalPromiseLoader<GetRegionInfo>({
    key: KARROT_BRIDGE_EXTERNAL_PROMISE_LOADER_KEY.REGION,
    onFailure: (error) => {
      captureException(error);
    },
  });

  return children;
};

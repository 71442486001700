import { QueryClient } from "@tanstack/react-query";
import { isAxiosError } from "axios";

import { calculateBackoff } from "@/shared/utils/time/calculateBackoff";
import { SECOND } from "@/shared/utils/time/constants";

const QUERY_MAX_RETRY_COUNT = 3;
const QUERY_MAX_RETRY_DELAY = 5 * SECOND;
const QUERY_NO_RETRY_HTTP_STATUS = [
  400, // Bad Request - client error, retrying won't help
  401, // Unauthorized - authentication needed
  403, // Forbidden - user doesn't have permission
  404, // Not Found - resource doesn't exist
  405, // Method Not Allowed - HTTP method not supported
  409, // Conflict - request conflicts with server state
  422, // Unprocessable Entity - semantic errors
];

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, error) => {
        // 최대 재시도 횟수를 초과한 경우 재시도하지 않는다.
        const isMaxRetryCountExceeded = failureCount >= QUERY_MAX_RETRY_COUNT;
        if (isMaxRetryCountExceeded) {
          return false;
        }
        // 재시도가 의미 없는 경우 재시도하지 않는다.
        const isNonRetryableError =
          isAxiosError(error) &&
          QUERY_NO_RETRY_HTTP_STATUS.includes(error.response?.status ?? 0);
        if (isNonRetryableError) {
          return false;
        }

        return true;
      },

      retryDelay(failureCount) {
        const delay = calculateBackoff({
          strategy: "exponential",
          attemptCount: failureCount,
          backoffLimit: QUERY_MAX_RETRY_DELAY,
        });

        return delay;
      },
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  },
});

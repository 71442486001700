import { captureException } from "@sentry/react";

import { businessGatewayAxios } from "@/shared/api/axios/businessGatewayAxios";

import { REMOTE_CONFIG_FALLBACK } from "./constants";
import { type RemoteConfig } from "./types";

export const getRemoteConfig = async ({
  group = "local-ugc",
}: {
  group?: "local-ugc";
}) => {
  return businessGatewayAxios
    .get<RemoteConfig>(`/remote-config/api/v1/parameters?group=${group}`)
    .then((response) => ({
      ...response,
      data: {
        ...REMOTE_CONFIG_FALLBACK,
        ...response.data,
      },
    }))
    .catch((error) => {
      captureException(error);

      return { data: REMOTE_CONFIG_FALLBACK };
    });
};

import { Screen } from "@/shared/ui/screen/Screen";

import { type ActivityLayoutComponentType } from "@/stackflow/routes/types";

type PathParams = {
  /**
   * 로컬프로필 ID
   */
  local_profile_id: string;
};
type QueryParams = {};
export type ActivityLocalProfileUpdateOperationTimeParams = PathParams &
  QueryParams;
const ActivityLocalProfileUpdateOperationTimeLayout: ActivityLayoutComponentType<
  ActivityLocalProfileUpdateOperationTimeParams
> = ({ children }) => {
  return (
    <Screen appBar={{ title: "영업 시간", border: false }}>{children}</Screen>
  );
};

export default ActivityLocalProfileUpdateOperationTimeLayout;

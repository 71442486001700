import { Screen } from "@/shared/ui/screen/Screen";

import { type ActivityLayoutComponentType } from "@/stackflow/routes/types";

type PathParams = {
  /**
   * 로컬프로필 ID
   */
  local_profile_id: string;
};
type QueryParams = {
  /**
   * 로컬프로필 이름
   */
  local_profile_name?: string;
};
export type ActivityLocalProfileUpdateCategoryParams = PathParams & QueryParams;
const ActivityLocalProfileUpdateCategoryLayout: ActivityLayoutComponentType<
  ActivityLocalProfileUpdateCategoryParams
> = ({ children }) => {
  return <Screen appBar={{ title: "업종", border: false }}>{children}</Screen>;
};

export default ActivityLocalProfileUpdateCategoryLayout;

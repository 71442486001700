import { type ReactNode, useLayoutEffect, useRef, useState } from "react";
import { assignInlineVars } from "@vanilla-extract/dynamic";
import { BoxButton } from "@daangn/sprout-components-button";

import { renderMultilineText } from "@/shared/utils/react/renderMultilineText";

import * as css from "./EmptyOrError.css";

interface Props {
  title?: string | ReactNode;
  body: string | ReactNode;
  CTAButton?: {
    label: string;
    onClick: () => void;
  };
  usePositionCenter?: boolean;
}
export const EmptyOrError = ({
  title,
  body,
  CTAButton,
  usePositionCenter,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const [offsetHeight, setOffsetHeight] = useState(0);

  const renderTitle = () => {
    if (!title) return null;

    if (typeof title === "string") {
      return (
        <div className={css.titleWrapper}>
          <h3 className={css.title}>{title}</h3>
        </div>
      );
    }

    return <div className={css.titleWrapper}>{title}</div>;
  };

  const renderBody = () => {
    if (!body) return null;

    if (typeof body === "string") {
      return (
        <p className={css.body({ hasTitle: !!title })}>
          {renderMultilineText(body)}
        </p>
      );
    }

    return body;
  };

  const renderCTAButton = () => {
    if (!CTAButton) return null;

    return (
      <div className={css.ctaButtonWrapper}>
        <BoxButton
          size="medium"
          type="button"
          variant="secondary"
          onClick={CTAButton.onClick}
          minWidth={120}
          aria-label={CTAButton.label}
        >
          {CTAButton.label}
        </BoxButton>
      </div>
    );
  };

  useLayoutEffect(() => {
    setOffsetHeight(ref.current?.offsetHeight ?? 0);
  }, []);

  return (
    <div
      ref={ref}
      className={css.container({
        usePositionCenter,
      })}
      style={assignInlineVars({
        [css.offsetHeight]: `calc(40% - (${offsetHeight}px) / 2)`,
      })}
    >
      {renderTitle()}
      {renderBody()}
      {renderCTAButton()}
    </div>
  );
};

import { BottomFixedButton } from "@/shared/ui/layout/BottomFixedButton";
import { Screen } from "@/shared/ui/screen/Screen";

import { type ActivityLayoutComponentType } from "@/stackflow/routes/types";
import { useNavigator } from "@/stackflow/useNavigator";

type PathParams = {
  /**
   * 후기 ID
   */
  review_id: string;
};

export type ActivitySmallBusinessDayEventReviewMoneyRewardParams = PathParams;

const ActivitySmallBusinessDayEventReviewMoneyRewardLayout: ActivityLayoutComponentType<
  ActivitySmallBusinessDayEventReviewMoneyRewardParams
> = ({ children }) => {
  const { pop } = useNavigator();

  return (
    <Screen>
      <BottomFixedButton
        actions={[
          {
            children: "확인",
            size: "xlarge",
            variant: "primary",
            onClick: () => {
              pop();
            },
          },
        ]}
      >
        {children}
      </BottomFixedButton>
    </Screen>
  );
};

export default ActivitySmallBusinessDayEventReviewMoneyRewardLayout;

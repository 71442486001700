import * as webviewRouter from "@daangn/webview-link-router";

import { IS_PROD } from "@/shared/constants/environment";

type Stage = Parameters<
  typeof webviewRouter.openMinikarrotWebview
>["0"]["stage"];

const stage = (IS_PROD ? "production" : "alpha") satisfies Stage;

export const openMinikarrotWebview = (
  params: Omit<
    Parameters<typeof webviewRouter.openMinikarrotWebview>["0"],
    "stage"
  >,
) =>
  webviewRouter.openMinikarrotWebview({
    ...params,
    stage,
  });

type CreateMinikarrotScheme = Omit<
  Parameters<typeof webviewRouter.createMinikarrotScheme>["0"],
  "stage"
>;
export const createMinikarrotScheme = (params: CreateMinikarrotScheme) =>
  webviewRouter.createMinikarrotScheme({ stage, ...params });

export function openExternalBrowser(args: { url: string }) {
  return webviewRouter.openExternalBrowser({ stage, url: args.url });
}

export function openInAppBrowser(args: { url: string }) {
  return webviewRouter.openInAppBrowser({ stage, url: args.url });
}

export function createInAppBrowserScheme(args: { url: string }) {
  return webviewRouter.createInAppBrowserScheme({ stage, url: args.url });
}

import { lazy } from "react";
import { LOCAL_MAPS_UGC_WEBVIEW_PATHS } from "@daangn/local-profile-suggestion-sdk";

import BaseActivityBottomSheetLayout from "@/activities/BaseActivityBottomSheet.layout";
import BaseActivityScreenLayout from "@/activities/BaseActivityScreen.layout";
import ActivityLocalProfileSuggestionLandingLayout from "@/activities/local-profile-suggestions/[local_profile_suggestion_id]/landing/ActivityLocalProfileSuggestionLanding.layout";
import ActivityLocalProfileSuggestionDeleteLayout from "@/activities/local-profiles/[local_profile_id]/suggestion/delete/ActivityLocalProfileSuggestionDelete.layout";
import ActivityLocalProfileUpdateLayout from "@/activities/local-profiles/[local_profile_id]/suggestion/update/ActivityLocalProfileUpdate.layout";
import ActivityLocalProfileUpdateAddressLayout from "@/activities/local-profiles/[local_profile_id]/suggestion/update/address/ActivityLocalProfileUpdateAddress.layout";
import ActivityLocalProfileUpdateBackgroundImageLayout from "@/activities/local-profiles/[local_profile_id]/suggestion/update/background-image/ActivityLocalProfileUpdateBackgroundImage.layout";
import ActivityLocalProfileUpdateCategoryLayout from "@/activities/local-profiles/[local_profile_id]/suggestion/update/category/ActivityLocalProfileUpdateCategory.layout";
import ActivityLocalProfileUpdateNameLayout from "@/activities/local-profiles/[local_profile_id]/suggestion/update/name/ActivityLocalProfileUpdateName.layout";
import ActivityLocalProfileUpdateOperationTimeLayout from "@/activities/local-profiles/[local_profile_id]/suggestion/update/operation-time/ActivityLocalProfileUpdateOperationTime.layout";
import ActivityLocalProfileUpdatePhoneLayout from "@/activities/local-profiles/[local_profile_id]/suggestion/update/phone/ActivityLocalProfileUpdatePhone.layout";
import ActivityLocalProfileUpdatePricingImageLayout from "@/activities/local-profiles/[local_profile_id]/suggestion/update/pricing-image/ActivityLocalProfileUpdatePricingImage.layout";
import ActivityLocalProfileUpdateWebsiteLayout from "@/activities/local-profiles/[local_profile_id]/suggestion/update/website/ActivityLocalProfileUpdateWebsite.layout";
import ActivityLocalProfileContributeLayout from "@/activities/local-profiles/contribute/ActivityLocalProfileContribute.layout";
import ActivityLocalProfileSuggestionCreateLayout from "@/activities/local-profiles/suggestion/create/ActivityLocalProfileSuggestionCreate.layout";
import ActivityLocalProfileSuggestionCreateLandingLayout from "@/activities/local-profiles/suggestion/create/landing/ActivityLocalProfileSuggestionCreateLanding.layout";
import ActivityLayoutUserProfile from "@/activities/profiles/[profile_user_id]/ActivityUserProfile.layout";
import ActivityLayoutUserProfileContributedLocalProfiles from "@/activities/profiles/[profile_user_id]/contributed-local-profiles/ActivityUserProfileContributedLocalProfiles.layout";
import ActivityUserContributionPointsHistoryLayout from "@/activities/profiles/[profile_user_id]/contribution-points-history/ActivityUserContributionPointsHistory.layout";
import ActivitySocialCapitalContributionPointsDetailsLayout from "@/activities/profiles/[profile_user_id]/contribution-points-history/details/ActivitySocialCapitalContributionPointsDetails.layout";
import ActivityLayoutUserProfileReviews from "@/activities/profiles/[profile_user_id]/local-profile-reviews/ActivityUserProfileReviews.layout";
import ActivityLayoutUserProfileSuggestions from "@/activities/profiles/[profile_user_id]/local-profile-suggestions/ActivityUserProfileSuggestions.layout";
import ActivityReviewActionSheetLayout from "@/activities/reviews/[review_id]/action-sheet/ActivityReviewActionSheet.layout";
import ActivityUpdateReviewLayout from "@/activities/reviews/[review_id]/edit/ActivityUpdateReview.layout";
import ActivityReviewPerformanceLandingLayout from "@/activities/reviews/[review_id]/performance-landing/ActivityReviewPerformanceLanding.layout";
import ActivityCreateReviewLayout from "@/activities/reviews/new/ActivityCreateReview.layout";
import ActivitySmallBusinessDayEventReviewMoneyRewardLayout from "@/activities/small-business-day-event/reviews/[review_id]/money-reward/ActivitySmallBusinessDayEventReviewMoneyReward.layout";
import ActivitySmallBusinessDayEventReviewQualityScoringLayout from "@/activities/small-business-day-event/reviews/[review_id]/quality-scoring/ActivitySmallBusinessDayEventReviewQualityScoring.layout";
import ActivitySocialCapitalContributeReviewLandingLayout from "@/activities/social-capital/reviews/[review_id]/contribute-landing/ActivitySocialCapitalContributeReviewLanding.layout";
import ActivitySocialCapitalReviewLandingLayout from "@/activities/social-capital/reviews/[review_id]/landing/ActivitySocialCapitalReviewLanding.layout";
import ActivitySocialCapitalContributeSuggestionLandingLayout from "@/activities/social-capital/suggestions/[suggestion_id]/contribute-landing/ActivitySocialCapitalContributeSuggestionLanding.layout";

const profileRouteMap = {
  /*
   * 유저 프로필
   * */

  // 유저 프로필 - 홈
  profile: {
    path: "/profiles/:profile_id" as const,
    layout: ActivityLayoutUserProfile,
    component: lazy(
      () =>
        import("@/activities/profiles/[profile_user_id]/ActivityUserProfile"),
    ),
  },
  // 유저 프로필 - 온보딩 바텀시트
  "profile.onboarding_bottom_sheet": {
    path: "/profiles/:profile_id/onboarding-bottom-sheet" as const,
    layout: BaseActivityBottomSheetLayout,
    component: lazy(
      () =>
        import(
          "@/activities/profiles/[profile_user_id]/onboarding-bottom-sheet/ActivityUserProfileOnboardingBottomSheet"
        ),
    ),
  },
  // 유저 프로필 - 후기 목록
  "profile.local_profile_reviews": {
    path: "/profiles/:profile_id/local-profile-reviews" as const,
    layout: ActivityLayoutUserProfileReviews,
    component: lazy(
      () =>
        import(
          "@/activities/profiles/[profile_user_id]/local-profile-reviews/ActivityUserProfileReviews"
        ),
    ),
  },
  // 유저 프로필 - 업체 제안 목록
  "profile.local_profile_suggestions": {
    path: "/profiles/:profile_id/local-profile-suggestions" as const,
    layout: ActivityLayoutUserProfileSuggestions,
    component: lazy(
      () =>
        import(
          "@/activities/profiles/[profile_user_id]/local-profile-suggestions/ActivityUserProfileSuggestions"
        ),
    ),
  },
  // 유저 프로필 - 기여한 로컬프로필 목록
  "profile.contributed_local_profiles": {
    path: "/profiles/:profile_id/contributed-local-profiles" as const,
    layout: ActivityLayoutUserProfileContributedLocalProfiles,
    component: lazy(
      () =>
        import(
          "@/activities/profiles/[profile_user_id]/contributed-local-profiles/ActivityUserProfileContributedLocalProfiles"
        ),
    ),
  },
  // 유저 프로필 - 기여 포인트 지급 내역
  "profile.contribution_points_history": {
    path: "/profiles/:profile_id/contribution-points-history" as const,
    layout: ActivityUserContributionPointsHistoryLayout,
    component: lazy(
      () =>
        import(
          "@/activities/profiles/[profile_user_id]/contribution-points-history/ActivityUserContributionPointsHistory"
        ),
    ),
  },
  // 소셜 캐피탈 - 기여 포인트 (동네가이드 점수) 지급 내역 상세
  "profile.contribution_points_history.details": {
    path: "/profiles/:profile_id/contribution-points-history/contribution-points/:contribution_points_id" as const,
    layout: ActivitySocialCapitalContributionPointsDetailsLayout,
    component: lazy(
      () =>
        import(
          "@/activities/profiles/[profile_user_id]/contribution-points-history/details/ActivitySocialCapitalContributionPointsDetails"
        ),
    ),
  },
};

const reviewRouteMap = {
  /*
   * 후기
   * */

  // 후기 작성
  "review.create": {
    path: "/reviews/new" as const,
    layout: ActivityCreateReviewLayout,
    component: lazy(
      () => import("@/activities/reviews/new/ActivityCreateReview"),
    ),
  },
  // 후기 상세
  review: {
    path: "/reviews/:review_id" as const,
    layout: BaseActivityScreenLayout,
    component: lazy(
      () => import("@/activities/reviews/[review_id]/ActivityReview"),
    ),
  },
  // 후기 발견
  "review.discover_local_profile": {
    path: "/reviews/:review_id/discover-local-profile" as const,
    layout: BaseActivityScreenLayout,
    component: lazy(
      () =>
        import(
          "@/activities/reviews/[review_id]/discover-local-profile/ActivityReviewDiscoverLocalProfile"
        ),
    ),
  },
  // 후기 수정
  "review.update": {
    path: "/reviews/:review_id/edit" as const,
    layout: ActivityUpdateReviewLayout,
    component: lazy(
      () =>
        import("@/activities/reviews/[review_id]/edit/ActivityUpdateReview"),
    ),
  },
  // 후기 수정/삭제 액션 시트
  "review.action_sheet": {
    path: "/reviews/:review_id/action-sheet" as const,
    layout: ActivityReviewActionSheetLayout,
    component: lazy(
      () =>
        import(
          "@/activities/reviews/[review_id]/action-sheet/ActivityReviewActionSheet"
        ),
    ),
  },
  // 후기 작성 후 랜딩페이지
  "review.landing": {
    path: "/reviews/:review_id/landing" as const,
    layout: BaseActivityScreenLayout,
    component: lazy(
      () =>
        import(
          "@/activities/reviews/[review_id]/landing/ActivityReviewLanding"
        ),
    ),
  },
  // 후기 성과 페이지
  "review.performance_landing": {
    path: "/reviews/:review_id/performance-landing" as const,
    layout: ActivityReviewPerformanceLandingLayout,
    component: lazy(
      () =>
        import(
          "@/activities/reviews/[review_id]/performance-landing/ActivityReviewPerformanceLanding"
        ),
    ),
  },
};

const localProfileRouteMap = {
  /*
   * 로컬프로필
   * */

  // 로컬프로필 제안 참여 완료 후 랜딩페이지
  "local_profile_suggestion.landing": {
    path: "/local-profile-suggestions/:local_profile_suggestion_id/landing" as const,
    layout: ActivityLocalProfileSuggestionLandingLayout,
    component: lazy(
      () =>
        import(
          "@/activities/local-profile-suggestions/[local_profile_suggestion_id]/landing/ActivityLocalProfileSuggestionLanding"
        ),
    ),
  },
  // 로컬프로필 제안 검수 통과 페이지
  "local_profile_suggestion.inspection_completed": {
    path: "/local-profile-suggestions/:local_profile_suggestion_id/inspection-completed" as const,
    layout: BaseActivityScreenLayout,
    component: lazy(
      () =>
        import(
          "@/activities/local-profile-suggestions/[local_profile_suggestion_id]/inspection-completed/ActivityLocalProfileSuggestionInspectionCompleted"
        ),
    ),
  },
  // 로컬프로필에 UGC 생산하기 홈 (기여모드)
  "local_profile.contribute": {
    path: "/local-profiles/contribute" as const,
    layout: ActivityLocalProfileContributeLayout,
    component: lazy(
      () =>
        import(
          "@/activities/local-profiles/contribute/ActivityLocalProfileContribute"
        ),
    ),
  },
  // 로컬프로필 생성 제안
  "local_profile.suggestion.create": {
    path: "/local-profiles/suggestion/create" as const,
    layout: ActivityLocalProfileSuggestionCreateLayout,
    component: lazy(
      () =>
        import(
          "@/activities/local-profiles/suggestion/create/ActivityLocalProfileSuggestionCreate"
        ),
    ),
  },
  // 로컬프로필 삭제 제안
  "local_profile.suggestion.delete": {
    path: "/local-profiles/:local_profile_id/suggestion/delete" as const,
    layout: ActivityLocalProfileSuggestionDeleteLayout,
    component: lazy(
      () =>
        import(
          "@/activities/local-profiles/[local_profile_id]/suggestion/delete/ActivityLocalProfileSuggestionDelete"
        ),
    ),
  },
  // 로컬프로필 생성 제안 완료
  "local_profile.suggestion.create_landing": {
    path: "/local-profiles/suggestion/create_landing" as const,
    layout: ActivityLocalProfileSuggestionCreateLandingLayout,
    component: lazy(
      () =>
        import(
          "@/activities/local-profiles/suggestion/create/landing/ActivityLocalProfileSuggestionCreateLanding"
        ),
    ),
  },
  // 로컬프로필 수정 제안 (deprecated - path 구조 변경 후 하위 호환을 위해)
  "local_profile.suggestion.deprecated_update": {
    path: "/local-profiles/:local_profile_id/update" as const,
    layout: ActivityLocalProfileUpdateLayout,
    component: lazy(
      () =>
        import(
          "@/activities/local-profiles/[local_profile_id]/suggestion/update/ActivityLocalProfileUpdate"
        ),
    ),
  },
  // 로컬프로필 수정 제안
  "local_profile.suggestion.update": {
    path: LOCAL_MAPS_UGC_WEBVIEW_PATHS.LOCAL_PROFILE_SUGGESTION_UPDATE,
    layout: ActivityLocalProfileUpdateLayout,
    component: lazy(
      () =>
        import(
          "@/activities/local-profiles/[local_profile_id]/suggestion/update/ActivityLocalProfileUpdate"
        ),
    ),
  },
  // 로컬프로필 배경 사진 제안
  "local_profile.suggestion.update.background_image": {
    path: LOCAL_MAPS_UGC_WEBVIEW_PATHS.LOCAL_PROFILE_SUGGESTION_UPDATE_BACKGROUND_IMAGE,
    layout: ActivityLocalProfileUpdateBackgroundImageLayout,
    component: lazy(
      () =>
        import(
          "@/activities/local-profiles/[local_profile_id]/suggestion/update/background-image/ActivityLocalProfileUpdateBackgroundImage"
        ),
    ),
  },
  // 로컬프로필 가격 사진 제안
  "local_profile.suggestion.update.pricing_image": {
    path: LOCAL_MAPS_UGC_WEBVIEW_PATHS.LOCAL_PROFILE_SUGGESTION_UPDATE_PRICING_IMAGE,
    layout: ActivityLocalProfileUpdatePricingImageLayout,
    component: lazy(
      () =>
        import(
          "@/activities/local-profiles/[local_profile_id]/suggestion/update/pricing-image/ActivityLocalProfileUpdatePricingImage"
        ),
    ),
  },
  // 로컬프로필 장소 이름 제안
  "local_profile.suggestion.update.name": {
    path: LOCAL_MAPS_UGC_WEBVIEW_PATHS.LOCAL_PROFILE_SUGGESTION_UPDATE_NAME,
    layout: ActivityLocalProfileUpdateNameLayout,
    component: lazy(
      () =>
        import(
          "@/activities/local-profiles/[local_profile_id]/suggestion/update/name/ActivityLocalProfileUpdateName"
        ),
    ),
  },
  // 로컬프로필 전화번호 제안
  "local_profile.suggestion.update.phone": {
    path: LOCAL_MAPS_UGC_WEBVIEW_PATHS.LOCAL_PROFILE_SUGGESTION_UPDATE_PHONE,
    layout: ActivityLocalProfileUpdatePhoneLayout,
    component: lazy(
      () =>
        import(
          "@/activities/local-profiles/[local_profile_id]/suggestion/update/phone/ActivityLocalProfileUpdatePhone"
        ),
    ),
  },
  // 로컬프로필 웹사이트 제안
  "local_profile.suggestion.update.website": {
    path: LOCAL_MAPS_UGC_WEBVIEW_PATHS.LOCAL_PROFILE_SUGGESTION_UPDATE_WEBSITE,
    layout: ActivityLocalProfileUpdateWebsiteLayout,
    component: lazy(
      () =>
        import(
          "@/activities/local-profiles/[local_profile_id]/suggestion/update/website/ActivityLocalProfileUpdateWebsite"
        ),
    ),
  },
  // 로컬프로필 업종 제안
  "local_profile.suggestion.update.category": {
    path: LOCAL_MAPS_UGC_WEBVIEW_PATHS.LOCAL_PROFILE_SUGGESTION_UPDATE_CATEGORY,
    layout: ActivityLocalProfileUpdateCategoryLayout,
    component: lazy(
      () =>
        import(
          "@/activities/local-profiles/[local_profile_id]/suggestion/update/category/ActivityLocalProfileUpdateCategory"
        ),
    ),
  },
  // 로컬프로필 주소 제안
  "local_profile.suggestion.update.address": {
    path: LOCAL_MAPS_UGC_WEBVIEW_PATHS.LOCAL_PROFILE_SUGGESTION_UPDATE_ADDRESS,
    layout: ActivityLocalProfileUpdateAddressLayout,
    component: lazy(
      () =>
        import(
          "@/activities/local-profiles/[local_profile_id]/suggestion/update/address/ActivityLocalProfileUpdateAddress"
        ),
    ),
  },
  // 로컬프로필 영업 시간 제안
  "local_profile.suggestion.update.operation_time": {
    path: LOCAL_MAPS_UGC_WEBVIEW_PATHS.LOCAL_PROFILE_SUGGESTION_UPDATE_OPERATION_TIME,
    layout: ActivityLocalProfileUpdateOperationTimeLayout,
    component: lazy(
      () =>
        import(
          "@/activities/local-profiles/[local_profile_id]/suggestion/update/operation-time/ActivityLocalProfileUpdateOperationTime"
        ),
    ),
  },
  // UGC를 생산할 로컬프로필 추천 목록
  "local_profile.contribute.recommendations": {
    path: "/local-profiles/contribute/recommendations" as const,
    layout: BaseActivityScreenLayout,
    component: lazy(
      () =>
        import(
          "@/activities/local-profiles/contribute/recommendations/ActivityLocalProfileContributeRecommendations"
        ),
    ),
  },
  // 로컬프로필 기여 목록
  "local_profile.contributions": {
    path: "/local-profiles/:local_profile_id/contributions" as const,
    layout: BaseActivityScreenLayout,
    component: lazy(
      () =>
        import(
          "@/activities/local-profiles/[local_profile_id]/contributions/ActivityLocalProfileContributionList"
        ),
    ),
  },
};

const socialCapitalRouteMap = {
  /*
   * 소셜 캐피탈
   * */
  // 소셜 캐피탈 - 후기 작성 완료
  "social_capital.review.landing": {
    path: "/social-capital/reviews/:review_id/landing" as const,
    layout: ActivitySocialCapitalReviewLandingLayout,
    component: lazy(
      () =>
        import(
          "@/activities/social-capital/reviews/[review_id]/landing/ActivitySocialCapitalReviewLanding"
        ),
    ),
  },
  // 소셜 캐피탈 - 기여모드 후기 작성 완료
  "social_capital.review.contribute_landing": {
    path: "/social-capital/reviews/:review_id/contribute-landing" as const,
    layout: ActivitySocialCapitalContributeReviewLandingLayout,
    component: lazy(
      () =>
        import(
          "@/activities/social-capital/reviews/[review_id]/contribute-landing/ActivitySocialCapitalContributeReviewLanding"
        ),
    ),
  },
  // 소셜 캐피탈 - 기여모드 제안 완료
  "social_capital.suggestion.contribute_landing": {
    path: "/social-capital/suggestions/:local_profile_suggestion_id/contribute-landing" as const,
    layout: ActivitySocialCapitalContributeSuggestionLandingLayout,
    component: lazy(
      () =>
        import(
          "@/activities/social-capital/suggestions/[suggestion_id]/contribute-landing/ActivitySocialCapitalContributeSuggestionLanding"
        ),
    ),
  },
};

/**
 * 소상공인의 날 후기 작성 이벤트 (동네지도 챌린지. 2024/11/12 ~ 2024/11/30)
 */
const smallBusinessDayEventRouteMap = {
  // 소상공인의 날 후기 작성 이벤트: 퀄리티 스코어링 w/ LLM
  "small_business_day_event.review.quality_scoring": {
    path: "/small-business-day-event/reviews/:review_id/quality-scoring" as const,
    layout: ActivitySmallBusinessDayEventReviewQualityScoringLayout,
    component: lazy(
      () =>
        import(
          "@/activities/small-business-day-event/reviews/[review_id]/quality-scoring/ActivitySmallBusinessDayEventReviewQualityScoring"
        ),
    ),
  },
  // 소상공인의 날 후기 작성 이벤트: 당근머니 지급
  "small_business_day_event.review.money_reward": {
    path: "/small-business-day-event/reviews/:review_id/money-reward" as const,
    layout: ActivitySmallBusinessDayEventReviewMoneyRewardLayout,
    component: lazy(
      () =>
        import(
          "@/activities/small-business-day-event/reviews/[review_id]/money-reward/ActivitySmallBusinessDayEventReviewMoneyReward"
        ),
    ),
  },
};

export const routeMap = {
  ...profileRouteMap,
  ...reviewRouteMap,
  ...localProfileRouteMap,
  ...socialCapitalRouteMap,
  ...smallBusinessDayEventRouteMap,

  /*
   * 404 페이지
   * */

  // 존재하지 않는 페이지 접근
  "error.not_found": {
    path: "/404" as const,
    layout: BaseActivityScreenLayout,
    component: lazy(() => import("@/activities/404/ActivityNotFound")),
  },
};

export type ProfileActivityKey = keyof typeof profileRouteMap;
export type ReviewActivityKey = keyof typeof reviewRouteMap;
export type LocalProfileActivityKey = keyof typeof localProfileRouteMap;
export type SocialCapitalActivityKey = keyof typeof socialCapitalRouteMap;

import { SafeBottomSheet } from "@/shared/ui/screen/SafeBottomSheet";

import { type ActivityLayoutComponentType } from "@/stackflow/routes/types";

import { type ActivityReviewActionSheetParams } from "./ActivityReviewActionSheet";

const ActivityReviewActionSheetLayout: ActivityLayoutComponentType<
  ActivityReviewActionSheetParams
> = ({ children }) => {
  return <SafeBottomSheet>{children}</SafeBottomSheet>;
};

export default ActivityReviewActionSheetLayout;
